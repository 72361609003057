<template>
	<component :is="contentComponent" />
</template>

<script>
import HomeMobileContent from "@/pages/mobile/index.vue";
import HomePCContent from "@/pages/pc/index.vue";

export default {
	data() {
		return {
			contentComponent: null,
		};
	},
	// created() {
	// 	let device = this.$ssrContext
	// 		? this.$ssrContext.device
	// 		: window["__INITIAL_STATE__"];
	// 	console.log(`deviece--> ${device}`);
	// 	if (device) {
	// 		const isMobile = /Mobile/.test(device);
	// 		this.contentComponent = isMobile
	// 			? HomeMobileContent
	// 			: HomePCContent;
	// 	}
	// },
	mounted() {
		this.checkDeviceType();
	},
	methods: {
		checkDeviceType() {
			const isMobile = window.innerWidth < 768; // 假设屏幕宽度小于768px为移动设备
			this.contentComponent = isMobile ? HomeMobileContent : HomePCContent;
		}
	}
};
</script>
