<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>


<script>
import "@/css/main.scss";
import "aos/dist/aos.css"; // You can also use <link> for styles

export default {
	name: "app",

	data() {
		return {
			screenWidth: null,
			is_mobile: null,
		};
	},
	created() {
		let device = this.$ssrContext
			? this.$ssrContext.device
			: window["__INITIAL_STATE__"];
		console.log(`deviece App--> ${device}`);
	},

	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	mounted() {
		this.$logEvent("show_home");

		if (typeof document !== 'undefined'){
			// Only initialize AOS in the client-side
			const AOS = require("aos");
			AOS.init();
		}
	},
};
</script>
