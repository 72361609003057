<template>
	<div class="home pc">
		<!-- 第一个模块：全屏背景图 -->
		<div class="fullscreen-background">
			<!-- <img src="@/assets/build.png" class="module-img" alt=""> -->
			<picture class="module-img">
				<source srcset="@/assets/build.png" type="image/png"> <!-- 如果不支持 WebP，将回退到 PNG -->
				<img src="@/assets/build.png" alt="build"> <!-- 如果上述两个源都不可用，将显示此图片 -->
			</picture>
			<div class="header">
				<div class="logo-row cursor">
					<img src="@/assets/logo.png" alt="logo"> 
					<h1>中宝富兴</h1>
				</div>
				<div class="head-btn-row">
					<div class="head-btn-item cursor">
						<a href="#">关于我们</a>
					</div>
					<div class="head-btn-item">
						<a href="mailto:contact@zbfxtech.com">联系我们</a>
					</div>
					<div class="head-btn-item">
						<a href="mailto:opportunity@zbfxtech.com">加入我们</a>
					</div>
				</div>
			</div>
			<!-- 可以放置一些内容或标题 -->
			<h1 class="module-1-title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
				致力于为全球用户带来优质的移动互联网服务
				<br/>实现数字内容的平等共享与获取
			</h1>

			<!-- <div class="zbfxtech-row">
				<div class="zbfxtech s" data-aos="fade-left" data-aos-delay="0" data-aos-duration="1200">
					<img src="@/assets/S.png" alt="">
				</div>
				<div class="zbfxtech y1" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1200">
					<img src="@/assets/Y.png" alt="">
				</div>
				<div class="zbfxtech n" data-aos="fade-left" data-aos-delay="400" data-aos-duration="1200">
					<img src="@/assets/N.png" alt="">
				</div>
				<div class="zbfxtech j" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1200">
					<img src="@/assets/J.png" alt="">
				</div>
				<div class="zbfxtech o" data-aos="fade-left" data-aos-delay="800" data-aos-duration="1200">
					<img src="@/assets/0.png" alt="">
				</div>
				<div class="zbfxtech y2" data-aos="fade-left" data-aos-delay="1000" data-aos-duration="1200">
					<img src="@/assets/Y.png" alt="">
				</div>
			</div> -->

		</div>

		<div class="normal-module module-2">
			<div class="company-info" data-aos="fade-up" data-aos-duration="1000">
				数字浪潮中，中宝富兴公司以创新为帆，科技为桨，开启全新的智慧征程。我们致力于打造前沿的互联网解决方案，为客户的数字未来注入无限可能。
			</div>

			<div class="company-adver-row">
				<div class="company-adver-item">
					<div class="company-adver-item-info-row" data-aos="fade-up" data-aos-duration="1000">
						<div class="company-adver-title">
							全球市场
						</div>
						<div class="company-adver-content">
							<ul>
								<li><span class="company-adver-content-title">- 软件开发：</span>我们拥有专业的开发团队，精通各种编程语言和技术框架，为你打造安全、稳定、高效的软件应用。无论是企业管理系统、移动应用还是电商平台，我们都能满足你的需求。</li>
								<li><span class="company-adver-content-title">- 数字营销：</span>在竞争激烈的互联网市场，我们的数字营销专家将为你制定精准的营销策略，提升品牌知名度，增加用户流量，实现商业价值最大化。</li>
								<li><span class="company-adver-content-title">- 用户体验设计：</span>以用户为中心，我们致力于打造极致的用户体验。从界面设计到交互流程，我们精心雕琢每一个细节，让你的产品在用户心中留下深刻的印象。</li>
							</ul>
						</div>
					</div>
					<div class="company-adver-graphy" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-offset="0">
						<picture>
							<source srcset="@/assets/graphy-1.webp" type="image/webp"> <!-- 如果浏览器支持 WebP，将使用此源 -->
							<source srcset="@/assets/graphy-1.png" type="image/png"> <!-- 如果不支持 WebP，将回退到 PNG -->
							<img src="@/assets/graphy-1.png" alt="graphy-1"> <!-- 如果上述两个源都不可用，将显示此图片 -->
						</picture>
					</div>
				</div>
				<!-- <div class="company-adver-item">
					<div class="company-adver-graphy" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-offset="0">
						<picture>
							<source srcset="@/assets/graphy-2.webp" type="image/webp">
							<source srcset="@/assets/graphy-2.png" type="image/png">
							<img src="@/assets/graphy-2.png" alt="graphy-2">
						</picture>
					</div>
					<div class="company-adver-item-info-row" data-aos="fade-up" data-aos-duration="1000">
						<div class="company-adver-title">
							业务
						</div>
						<div class="company-adver-content">
							公司总部位于闻名遐迩的“中国硅谷”——中关村上地，这里不仅是技术创新的热土，也是我们企业精神的发源地。我们将移动应用的研发、运营和发行紧密结合，形成了一条高效且创新的产业链。在这个过程中，我们包括社交互动、休闲游戏、AI陪伴等多款产品逐步赢得了市场认可，并在众多竞争中脱颖而出。
						</div>
					</div>

				</div> -->
			</div>

		</div>

		<div class="normal-module module-3 ">
			<div class="mission-row">
				<div class="mission-item l" data-aos="fade-up" data-aos-duration="1000">
					<span>中宝富兴一家充满活力与创造力的互联网先锋。我们由一群怀揣梦想的技术精英和创新者组成，凭借对互联网行业的深刻洞察和无限热情，不断突破边界，引领行业发展潮流。</span>

				</div>
				<div class="mission-item r" data-aos="fade-up" data-aos-duration="1000">
					<span>我们专注于软件开发、数字营销、用户体验设计等领域，以精湛的技术和贴心的服务，为客户量身定制个性化的互联网解决方案。从初创企业到行业巨头，我们携手合作伙伴，共同书写互联网时代的辉煌篇章。</span>
				</div>
			</div>
		</div>

		<!-- 后续模块 -->
		<div class="normal-module module-4 ">

			<swiper class="swiper" :options="swiperOption">
				<swiper-slide :style="backgroundStyle(1)"></swiper-slide>
				<swiper-slide :style="backgroundStyle(2)"></swiper-slide>
				<swiper-slide :style="backgroundStyle(3)"></swiper-slide>
			</swiper>

			<div class="our-team-row">
				<div class="our-team-title" data-aos="fade-up" data-aos-duration="1000">
					我们的团队
				</div>
				<div class="our-team-content" data-aos="fade-up" data-aos-duration="1000">
					我们的团队是一支由行业精英组成的强大力量。技术专家们拥有丰富的开发经验和深厚的技术功底，能够应对各种复杂的技术挑战；设计团队独具匠心，以敏锐的审美眼光打造出令人惊艳的用户界面；营销团队则充满创意和活力，善于制定精准的营销策略，提升品牌影响力。
					<br/>在这里，每一位成员都充满激情和创造力，不断追求卓越。我们相互学习、共同成长，为了实现公司的目标而努力拼搏。
				</div>
				<!-- <div class="our-team-logo-row">
					<div class="team-logo-item" v-for="i in 8">
						<img :src="require(`@/assets/t-${i}.png`)" alt="">
					</div>
				</div> -->
			</div>
		</div>

		<div class="normal-module module-5">

			<div class="vision-row" data-aos="fade-up" data-aos-duration="1000">
				<div class="vision-title">
					企业价值观
				</div>
				<div class="vision-content">
					<ul>
						<li><span class="vision-content-title">- 软件开发：</span>我们拥有专业的开发团队，精通各种编程语言和技术框架，为你打造安全、稳定、高效的软件应用。无论是企业管理系统、移动应用还是电商平台，我们都能满足你的需求。</li>
						<li><span class="vision-content-title">- 数字营销：</span>在竞争激烈的互联网市场，我们的数字营销专家将为你制定精准的营销策略，提升品牌知名度，增加用户流量，实现商业价值最大化。</li>
						<li><span class="vision-content-title">- 用户体验设计：</span>以用户为中心，我们致力于打造极致的用户体验。从界面设计到交互流程，我们精心雕琢每一个细节，让你的产品在用户心中留下深刻的印象。</li>
					</ul>
				</div>
			</div>

			<div class="circle-row">
				<div class="circle circle-1">
					<div class="circle-content">
						<div class="circle-c">
							实事求是
						</div>
						<div class="circle-t">
							Seek truth from facts
						</div>
					</div>
				</div>
				<div class="circle circle-2">
					<div class="circle-content">
						<div class="circle-c">
							知行合一
						</div>
						<div class="circle-t">
							Unity of knowledge and action
						</div>
					</div>
				</div>
				<div class="circle circle-3"></div>
				<div class="circle circle-4">
					<div class="circle-content">
						<div class="circle-c">
							坚持真实
						</div>
						<div class="circle-t">
							Keep it real
						</div>
					</div>
				</div>
				<div class="circle circle-5">
					<div class="circle-content">
						<div class="circle-c">
							客观的态度
						</div>
						<div class="circle-t">
							Objective attitude
						</div>
					</div>
				</div>
			</div>

		</div>

		<footer class="normal-module module-6">
			<div class="footer-content-row">
				<div class="logo-row cursor">
					<img src="@/assets/logo.png" alt="logo">
					<span>中宝富兴</span>
				</div>
				<div class="jump-url">
					<div class="jump-url-item cursor">
						<a href="#">关于我们</a>
					</div>
					<div class="jump-url-item">
						<a href="mailto:opportunity@zbfxtech.com">加入我们</a>
					</div>
				</div>
				<div class="call-us-row">
					<div class="call-us-head">
						我们很乐意听取您的意见!
					</div>
					<div class="call-us-item">
						您可以通过以下渠道联系我们:
					</div>
					<div class="call-us-item">
						<a href="mailto:contact@zbfxtech.com">contact@zbfxtech.com</a>
					</div>
				</div>
			</div>

			<div class="dash-line"></div>
			<div class="copyright">
				© Copyright 2024 Zbfxtech Co., Limited
			</div>

		</footer>

	</div>
</template>

<script>
import "@/css/pc.scss";
import "@/css/main.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import "swiper/swiper-bundle.css";

// import swiper module styles
// import "swiper/css";
// import "swiper/css/pagination";

export default {
	name: "pc",
	data() {
		return {
			swiperOption: {
				loop: true,
				autoplay: {
					delay: 3000, // 自动切换的时间间隔，单位为毫秒
					disableOnInteraction: false, // 用户操作后是否停止自动播放
				},
			},
		};
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	methods: {
		backgroundStyle(index) {
			const images = [
				require("@/assets/carousel-1.webp"),
				require("@/assets/carousel-2.webp"),
				require("@/assets/carousel-3.webp"),
			];
			return {
				backgroundImage: `url(${images[index - 1]})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				height: "6.25rem",
			};
		},
	},
};
</script>
